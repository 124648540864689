<template>
  <div>
    <b-card class="m-2">
      <b-row>
        <b-col md="6">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Settlement Report ID :
            </dt>
            <dd class="col-sm-7">
              {{ settlementReportId }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Merchant :
            </dt>
            <dd class="col-sm-7">
              {{ merchantName }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Report Period :
            </dt>
            <dd class="col-sm-7">
              {{ startDate }} ~ {{ endDate }}
            </dd>
          </dl>
        </b-col>

        <b-col md="6">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              SalesStatement Report ID :
            </dt>
            <dd class="col-sm-7">
              {{ salesStatementReportId }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Report Generated Date :
            </dt>
            <dd class="col-sm-7">
              {{ createTime }} By {{ createBy }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Exchange Rate :
            </dt>
            <dd class="col-sm-7">
              <b-form-input
                v-model="detail.total.rate"
                size="sm"
                type="number"
                step="0.0001"
                trim
                @change="exchangeRateUpdate"
              />
            </dd>
          </dl>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="m-2">
      <b-row
        class="bg-info"
      >
        <b-col
          md="6"
          class="font-weight-bold h3"
        >
          Description
        </b-col>
        <b-col
          md="3"
          class="font-weight-bold h3 text-center"
        >
          Amount
        </b-col>
        <b-col
          md="3"
          class="font-weight-bold h3 text-center"
        >
          Settlement Amount
        </b-col>
      </b-row>
      <!-- Received Amount -->
      <b-row
        align-v="center"
        class="bg-info bg-lighten-1"
      >
        <b-col
          md="6"
          class="p-1"
        >
          {{ detail.total.name || 'Received Amount' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.total.source_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.total.source_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.total.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.total.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Chargeback -->
      <b-row
        align-v="center"
      >
        <b-col
          md="6"
          class="p-1 pl-3"
        >
          {{ detail.chargeback.name || '(-) Chargeback Amount' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.chargeback.source_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.chargeback.source_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.chargeback.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.chargeback.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Chargeback Covered -->
      <b-row
        align-v="center"
      >
        <b-col
          md="6"
          class="p-1 pl-3"
        >
          {{ detail.chargeback_covered.name || '(+) Chargeback Amount Covered by Pagsmile' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.chargeback_covered.source_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.chargeback_covered.source_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.chargeback_covered.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.chargeback_covered.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Refund -->
      <b-row
        align-v="center"
      >
        <b-col
          md="6"
          class="p-1 pl-3"
        >
          {{ detail.refund.name || '(-) Refund Amount' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.refund.source_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.refund.source_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.refund.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.refund.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Tax -->
      <b-row
        align-v="center"
      >
        <b-col
          md="6"
          class="p-1 pl-3"
        >
          {{ detail.tax.name || '(-) Tax Fee' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.tax.source_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.tax.source_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.tax.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.tax.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Processing -->
      <b-row
        align-v="center"
      >
        <b-col
          md="6"
          class="p-1 pl-3"
        >
          {{ detail.processing_fee.name || '(-) Processing Fee' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.processing_fee.source_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.processing_fee.source_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.processing_fee.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.processing_fee.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Settleable -->
      <b-row
        align-v="center"
        class="bg-info bg-lighten-1"
      >
        <b-col
          md="6"
          class="p-1"
        >
          {{ detail.settleable.name || 'Settleable Amount' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.settleable.source_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.settleable.source_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.settleable.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.settleable.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Chargeback Fee -->
      <b-row
        align-v="center"
      >
        <b-col
          md="6"
          class="p-1 pl-3"
        >
          {{ detail.chargeback_fee.name || 'Chargeback Fee' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.chargeback_fee.source_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.chargeback_fee.source_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.chargeback_fee.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.chargeback_fee.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Refund Fee -->
      <b-row
        align-v="center"
      >
        <b-col
          md="6"
          class="p-1 pl-3"
        >
          {{ detail.refund_fee.name || 'Refund Fee' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.refund_fee.source_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.refund_fee.source_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.refund_fee.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.refund_fee.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- AVS Fee -->
      <b-row
        align-v="center"
      >
        <b-col
          md="6"
          class="p-1 pl-3"
        >
          {{ detail.avs_fee.name || 'AVS Fee' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.avs_fee.source_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.avs_fee.source_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.avs_fee.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.avs_fee.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Settlement -->
      <b-row
        align-v="center"
        class="bg-info bg-lighten-1"
      >
        <b-col
          md="6"
          class="p-1"
        >
          {{ detail.settlement.name || 'Settlement Amount' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        />
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.settlement.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.settlement.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Settlement Fee -->
      <b-row
        align-v="center"
      >
        <b-col
          md="6"
          class="p-1 pl-3"
        >
          {{ detail.settlement_fee.name || 'Settlement Fee' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.settlement_fee.source_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.settlement_fee.source_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.settlement_fee.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.settlement_fee.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Adjustment -->
      <b-row
        align-v="center"
      >
        <b-col
          md="6"
          class="p-1 pl-3"
        >
          {{ detail.adjustment.name || 'Adjustment' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          --
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.adjustment.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.adjustment.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- Payout -->
      <b-row
        align-v="center"
        class="bg-info bg-lighten-5"
      >
        <b-col
          md="6"
          class="p-1 pl-3"
        >
          {{ detail.payout.name || 'Payout Amount' }}
        </b-col>
        <b-col
          md="3"
          class="p-1 text-center"
        />
        <b-col
          md="3"
          class="p-1 text-center"
        >
          <b-input-group
            :prepend="detail.payout.target_amount.currency"
            class="input-group-merge"
            size="sm"
          >
            <b-form-input
              v-model="detail.payout.target_amount.amount"
              class="text-center"
              type="number"
              step="0.01"
              trim
              :formatter="currencyFormatter"
            />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- Upload Report & Invoice -->
    <b-card v-if="$can('write', 'Settlement.Report')" class="m-2">
      <b-row>
        <b-col md="6">
          <b-form-file
            v-model="reportFile"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
        </b-col>
        <b-col md="6">
          <b-form-file
            v-model="invoiceFile"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6">
          <b-button
            v-if="reportFile"
            variant="primary"
            @click="uploadReport"
          >
            Upload
          </b-button>
          <b-button
            v-if="reportFile"
            class="ml-1"
            variant="primary"
            @click="reportFile = null"
          >
            Clear
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button
            v-if="invoiceFile"
            variant="primary"
            @click="uploadInvoice"
          >
            Upload
          </b-button>
          <b-button
            v-if="invoiceFile"
            class="ml-1"
            variant="primary"
            @click="invoiceFile = null"
          >
            Clear
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- Save Button -->
    <b-card v-if="$can('write', 'Settlement.Report')" class="m-2">
      <b-button
        variant="primary"
        @click="saveConfirm"
      >
        Save
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BInputGroup, BFormFile, BButton,
} from 'bootstrap-vue'
import router from '@/router'
import { showToast } from '@/libs/tool'
import useSettlementJs from './settlement'

const {
  querySettlementReportDetail,
  updateSettlementDetail,
  uploadReportFile,
} = useSettlementJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BFormFile,
    BButton,
  },
  filters: {
    numberFilter(value) {
      let realVal = ''
      if (value || value === 0) {
        realVal = parseFloat(value).toFixed(2)
      } else {
        realVal = '--'
      }
      return realVal
    },
  },
  data() {
    return {
      settlementId: router.currentRoute.params.id,
      settlementReportId: router.currentRoute.query.settlement_report_id,
      salesStatementReportId: router.currentRoute.query.sales_statement_report_id,
      startDate: router.currentRoute.query.start_date,
      endDate: router.currentRoute.query.end_date,
      merchantName: router.currentRoute.query.merchant_name,
      createTime: router.currentRoute.query.create_time,
      createBy: router.currentRoute.query.create_by,
      reportFile: null,
      invoiceFile: null,
      detail: {
        total: {
          name: '',
          rate: 1,
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        chargeback: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        chargeback_covered: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        refund: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        tax: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        processing_fee: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        settleable: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        chargeback_fee: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        refund_fee: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        avs_fee: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        settlement: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        settlement_fee: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        adjustment: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
        payout: {
          name: '',
          source_amount: {
            currency: '',
            amount: 0,
          },
          target_amount: {
            currency: '',
            amount: 0,
          },
        },
      },
    }
  },
  created() {
    this.querySettlementReportDetail()
  },
  methods: {
    currencyFormatter(number) {
      let ret = number
      if (ret === '') {
        ret = 0
      }
      return parseFloat(ret).toFixed(2)
    },
    exchangeRateUpdate() {
      const { rate } = this.detail.total

      this.detail.total.target_amount.amount = Math.floor(this.detail.total.source_amount.amount * rate * 100) / 100
      this.detail.chargeback.target_amount.amount = Math.floor(this.detail.chargeback.source_amount.amount * rate * 100) / 100
      this.detail.chargeback_covered.target_amount.amount = Math.floor(this.detail.chargeback_covered.source_amount.amount * rate * 100) / 100
      this.detail.refund.target_amount.amount = Math.floor(this.detail.refund.source_amount.amount * rate * 100) / 100
      this.detail.tax.target_amount.amount = Math.floor(this.detail.tax.source_amount.amount * rate * 100) / 100
      this.detail.processing_fee.target_amount.amount = Math.floor(this.detail.processing_fee.source_amount.amount * rate * 100) / 100

      this.resum()
    },
    resum() {
      this.detail.settleable.target_amount.amount = parseFloat(this.detail.total.target_amount.amount)
        + parseFloat(this.detail.chargeback.target_amount.amount)
        + parseFloat(this.detail.chargeback_covered.target_amount.amount)
        + parseFloat(this.detail.refund.target_amount.amount)
        + parseFloat(this.detail.tax.target_amount.amount)
        + parseFloat(this.detail.processing_fee.target_amount.amount)

      this.detail.settlement.target_amount.amount = parseFloat(this.detail.settleable.target_amount.amount)
        + parseFloat(this.detail.chargeback_fee.target_amount.amount)
        + parseFloat(this.detail.refund_fee.target_amount.amount)
        + parseFloat(this.detail.avs_fee.target_amount.amount)

      this.detail.payout.target_amount.amount = parseFloat(this.detail.settleable.target_amount.amount)
        + parseFloat(this.detail.settlement_fee.target_amount.amount)
        + parseFloat(this.detail.adjustment.target_amount.amount)
    },
    querySettlementReportDetail() {
      querySettlementReportDetail(
        this.settlementId,
        detail => {
          this.initDetail(detail)
        },
        fail => {
          showToast(this, 'Warning', `Query Settlement Report Detail Failed with ${fail}`)
        },
      )
    },
    initDetail(detail) {
      this.detail = detail
    },
    uploadReport() {
      const params = {
        fileType: 2,
        settlementId: this.settlementId,
        formData: this.reportFile,
      }
      uploadReportFile(
        params,
        () => {
          showToast(this, 'Success', 'Upload Success.')
        },
        fail => {
          showToast(this, 'Warning', `Upload Failed with ${fail}`)
        },
      )
    },
    uploadInvoice() {
      const params = {
        fileType: 3,
        settlementId: this.settlementId,
        formData: this.reportFile,
      }
      uploadReportFile(
        params,
        () => {
          showToast(this, 'Success', 'Upload Success.')
        },
        fail => {
          showToast(this, 'Warning', `Upload Failed with ${fail}`)
        },
      )
    },
    saveConfirm() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.saveReportDetail()
        }
      })
    },
    saveReportDetail() {
      const params = {
        settlement_id: this.settlementId,
        data: this.detail,
      }
      updateSettlementDetail(
        params,
        () => {
          showToast(this, 'Success', 'Update Settlement Detail Success.')
        },
        fail => {
          showToast(this, 'Warning', `Update Settlement Detail Failed with ${fail}`)
        },
      )
    },
  },
}
</script>
